<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The data table below was acquired for the theoretical reaction shown below. Note that
        compounds A, B, C, and D are all completely fictional.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="A(aq) + B(l) -> C(aq) + D(aq)" />
      </p>

      <v-simple-table style="max-width: 180px">
        <thead>
          <tr>
            <th>
              <stemble-latex content="$\text{Time (h)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex content="$\text{[A] (M)}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(timeValue, i) in timeValues" :key="timeValue">
            <td>
              {{ timeValues[i].toFixed(2) }}
            </td>
            <td style="text-align: center">
              {{ concValues[i].toFixed(4) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) In order to determine the order of the reaction, it is necessary to use graphical
        methods. In the graph, that gave rise to a straight line, what was plotted on the y-axis?
      </p>

      <s-textarea v-model="inputs.yAxis" class="mb-4" outlined></s-textarea>

      <p class="mb-n3">b) What is the order of this reaction?</p>

      <v-radio-group v-model="inputs.order" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsOrder" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        c) Calculate the rate constant,
        <stemble-latex content="$\text{k,}$" />
        for the reaction? Please select the appropriate units as well.
      </p>

      <p class="mb-1">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 mr-2 pr-0" cols="6" sm="4">
            <calculation-input
              v-model="inputs.rateConstant"
              prepend-text="$\text{k}:$"
              :disabled="!allowEditing"
            />
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="3">
            <v-select
              v-model="inputs.units"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select Units"
              outlined
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-2">
        d) What concentration of A(aq) will remain after the reaction has been allowed to run for
        <number-value :value="rxnTime" unit="\text{hr?}" />
      </p>

      <calculation-input
        v-model="inputs.concA"
        prepend-text="$\text{[A]}_\text{t}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question475',
  components: {
    NumberValue,
    CalculationInput,
    STextarea,
    AiLoadingOverlay,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        yAxis: null,
        order: null,
        rateConstant: null,
        units: null,
        concA: null,
      },
      optionsOrder: [
        {expression: '$\\text{Zeroth}$', value: 'zeroth'},
        {expression: '$\\text{First}$', value: 'first'},
        {expression: '$\\text{Second}$', value: 'second'},
        {expression: '$\\text{Third}$', value: 'third'},
        {expression: '$\\text{More information needed}$', value: 'moreInfoNeeded'},
      ],
      items: [
        {
          text: 'M/h',
          value: 'M/s',
        },
        {
          text: 'M<sup>-1</sup> h<sup>-1</sup>',
          value: 'M-1s-1',
        },
        {
          text: 'M<sup>-2</sup> h<sup>-1</sup>',
          value: 'M-2s-1',
        },
        {
          text: 'h<sup>-1</sup>',
          value: 's-1',
        },
        {
          text: 'M h<sup>-2</sup>',
          value: 'Ms-2',
        },
      ],
    };
  },

  computed: {
    Aconc0() {
      return this.taskState.getValueBySymbol('Aconc0').content;
    },
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    timeValues() {
      return [0.0, 3.15, 6.2, 10.0, 18.3, 30.8];
    },
    concValues() {
      return this.timeValues.map(
        (time) => this.Aconc0.toFloat() * Math.exp(-this.rateConstant.toFloat() * time)
      );
    },
    rxnTime() {
      return this.taskState.getValueBySymbol('rxnTime').content;
    },
  },
};
</script>
